import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('access_token');    
    if (token) {
      const headers: any = {
        authorization: `Bearer ${JSON.parse(token).accessToken}`
      };

      req = req.clone({
        setHeaders: headers
      });
    }
    return next.handle(req).pipe(
      catchError(err => {
        if ([401].includes(err.status)) {
          localStorage.clear();
        }
        const error = err.error?.message || err.statusText;
        return throwError(() => error);
      })
    );
  }
}
