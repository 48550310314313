import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { catchError, map, Observable } from "rxjs";

export interface UserInfo {
  id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  phone: string;
  email: string;
  invited_by_code: string;
  invite_code: string;
  status: string;
  username_id: string;
  username: string;
  country_name?: string;
  area_code: string;
  register_date: string;
  avatar_thumbnail_url: string;
  frame_url: string;
  roles: string[];
  created_at?: string;
  updated_at?: string;
  created_by?: string;
  updated_by?: string;
  is_verified_user?: boolean;
  is_friend?: boolean;
  city_code?: string;
  penalty_point?: number;
  relationship_status?: string[];
  country_code?: string;
  blocked_bloom: string;
  blocking_bloom: string;
  number_of_friend: number;
  number_of_sent_friend_invitation: number;
  number_of_received_friend_invitation: number;
  number_of_following: number;
  number_of_followed: number;
  post_privacy?: string;
  comment_post_privacy?: string;
  allow_tag_moderation: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = environment.baseURL;

  constructor(
    public readonly http: HttpClient
  ) {}

  getUserInfo(userId: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + `/user/users/${userId}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}